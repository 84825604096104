export const accordiondata = [
  {
    id: 1,
    title: '1. About Us',
    content: [
      'The domain name, Website and the Apps are owned, registered and operated by TAUTMORE LEARNING PRIVATE LIMITED, a private company incorporated under the (Indian) Companies Act, 2013, and having its registered office at 7-113, Old SBI Street, Gollapudi Krishna, Vijayawada, Andhra Pradesh – 521225, India, (hereinafter referred to as “TautMore” or “ us” or “we” or “our” or “Company”).',
    ],
  },

  {
    id: 2,
    title: '2. TautMore Platform',
    content: [
      '1. These Terms and Conditions apply to all Users of the TautMore Platform,including educators who are also contributors of User Content on the TautMore Platform. The TautMore Platform includes all aspects of theWebsite and Apps which includes but is not limited to products, software andservice offered via the TautMore Platform, such as the ______________ and any other service or application that TautMore introduces from time to time.',

      '2. TautMore Platform is an online platform that supports and facilitates the online creation of educational videos/or tutorials, by the Users of the TautMore Platform and acts as an intermediary between the educator and the User. A User may create a video, audio clip or tutorial, by using the TautMore Platform. Such content uploaded through the use of the TautMore Platform shall hereinafter be referred to as “User Content”. You agree and acknowledge that TautMore has no control over and assumes no responsibility for, the User Content and by using the TautMore Platform, you expressly relieve TautMore from any and all liability arising from the User Content.',

      '3. The TautMore Platform may include or contain links to any third-party websites that may or may not be owned or controlled by TautMore. TautMore has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third-party websites. In addition, TautMore will not and cannot censor or edit the content of any third-party site. By using the TautMore Platform, you expressly relieve TautMore from any and all liability arising from your use of any third-party website or services. However, we recommend that before you use any third-party services or website you read and understand the terms and conditions and privacy policy of each such website that you visit.',

      '4. Subject to these Terms and Conditions, Privacy Policy and all other rules and policies made available or published elsewhere, TautMore hereby grants you a non-exclusive, non-transferable, non-sublicensable, limited license to use the TautMore Platform in accordance with these Terms and Conditions.',

      '5. You agree and acknowledge that TautMore shall have the right at any time to change or discontinue any aspect or feature of the TautMore Platform, including, but not limited to, the User Content, hours of availability and equipment needed for access or use. Further, TautMore may discontinue disseminating any portion of information or category of information may change or eliminate any transmission method and may change transmission speeds or other signal characteristics. TautMore reserves the right to refuse access to the TautMore Platform, terminate Accounts, remove or edit contents without any notice to You.',
    ],
  },

  {
    id: 3,
    title: '3. TautMore Accounts',
    content: [
      'In order to access some of the features of the TautMore Platform, you may have to create your account with TautMore. You agree and confirm that you will never use another User’s account nor provide access to your account to any third- party. When creating your account, you confirm that the information so provided is accurate and complete. Further, you agree that you are solely responsible for the activities that occur on your account, and you shall keep your account password secure and not share the same with anyone. You must notify TautMore immediately of any breach of security or unauthorized use of your account. At no point in time will TautMore be liable for any losses caused by any unauthorized use of your account, you shall solely be liable for the losses caused to TautMore or others due to such unauthorized use, if any.',

      'TautMore takes no responsibility for any User Content that is uploaded on the TautMore Platform, and further, the User shall be solely responsible for his or her own actions in utilizing such User Content and availing the TautMore Platform provided herein.',
    ],
  },

  {
    id: 4,
    title: '4. Access, Permissions and Restrictions',
    content: [
      `TautMore hereby grants you permission to access and use the TautMore Platform as set forth in these Terms and Conditions, provided that:`,
      '1. You agree not to distribute in any medium any part of the TautMore Platform or the content without our prior written authorization.',
      '2. You agree not to alter or modify any part of the TautMore Platform.',
      '3. You agree not to access content of any other User through any technology or means other than the videos available on the TautMore Platform itself.',
      '4. You agree not to use the TautMore Platform for any of the following commercial uses unless you obtain our prior written approval:',
      ' the sale of access to the TautMore Platform;',
      ' the sale of advertising, sponsorships, or promotions placed on or within the TautMore Platform or content; or',
      ' the sale of advertising, sponsorships, or promotions on any page or website that provide similar TautMore Platform as that of TautMore.',

      `1. You agree to receive installs and updates from time to time from TautMore.These updates are designed to improve, enhance and further develop the TautMore Platform and may take the form of bug fixes, enhanced functions, new software modules and completely new versions. You agree to receive such updates (and permit TautMore to deliver these to you) as part of your use of the TautMore Platform.`,

      `2. You agree not to use or launch any automated system, including without limitation, “robots,” “spiders,” or “offline readers,” that accesses the TautMore
          Platform in a manner that sends more request messages to TautMore’s
          servers in a given period of time than a human can reasonably produce in the
          same period by using a conventional on-line web browser. Notwithstanding
          the foregoing, TautMore grants the operators of public search engines
          permission to use spiders to copy materials from the site for the sole purpose
          of and solely to the extent necessary for creating publicly available searchable
          indices of the materials, but not caches or archives of such materials.
          TautMore reserves the right to revoke these exceptions either generally or in
          specific cases at any time with or without providing any notice in this regard.
          You agree not to collect or harvest any personally identifiable information,
          including account names, from the TautMore Platform, nor to use the
          communication systems provided by the TautMore Platform (e.g., comments,
          email) for any commercial solicitation purposes. You agree not to solicit, for
          commercial purposes, any Users of the TautMore Platform with respect to
          User Content.`,

      `3. You may post reviews, comments and other content; send other
          communications; and submit suggestions, ideas, comments, questions or
          
          other information as long as the content is not illegal, obscene, threatening,
          defamatory, invasive of privacy, infringement of intellectual property rights, or
          otherwise injurious to third parties or objectionable and does not consist of or
          contain software viruses, political campaigning, commercial solicitation, chain
          letters, mass mailings or any other form of spam. Further, you give TautMore,
          royalty free, worldwide, non-exclusive license to use the User Content and
          communication in developing its TautMore Platform and in any of its
          marketing or promotional activities.`,

      `4. In your use of the TautMore Platform, you will at all times comply with all
          applicable laws and regulations.`,

      `5. TautMore reserves the right to discontinue any aspect of the TautMore
          Platform at any time with or without notice at its sole discretion.`,
    ],
  },

  {
    id: 5,
    title: '5. Content Use',
    content: [
      `In addition to the general restrictions mentioned above, the following limitation and
        conditions shall apply to your use of the Content.`,
      ` 1. TautMore’s Content utilized on the TautMore Platform which shall include but
        not be limited to trademarks, service marks and logos (“Marks”), process,
        images, software, graphics are owned by or licensed to TautMore and subject
        to copyright and other intellectual property rights under the law.`,
      `2. User Content is provided to you on an AS IS basis.
        You may access Content for your information and personal use solely as
        intended through the provided functionality on the TautMore Platform and as
        permitted under these Terms and Conditions. You shall not download any
        User Content unless you see a “download” or similar link displayed by
        TautMore on the TautMore Platform for that User Content. You shall not copy,
        reproduce, make available online or electronically transmit, publish, adapt,
        distribute, transmit, broadcast, display, sell, license, or otherwise exploit any
        User Content for any other purposes other than as provided herein without the
        prior written consent of TautMore or the respective licensors of the User
        Content. TautMore and its licensors reserve all rights not expressly granted in
        and to the TautMore Platform and the User Content.`,
      ` 3. You agree not to circumvent, disable or otherwise interfere with security-
        related features of the TautMore Platform or features that prevent or restrict
        use or copying of any User Content or enforce limitations on use of the
        TautMore Platform or the User Content therein.,`,
      `4. You understand that when using the TautMore Platform, you will be exposed
        to User Content from variety of sources and by different Users, and that
        TautMore is not responsible for the accuracy, usefulness, safety, or
        intellectual property rights of or relating to such User Content. You further
        understand and acknowledge that you may be exposed to User Content that
        is inaccurate, offensive, indecent, or objectionable, and you agree to waive,
        and hereby do waive, any legal or equitable rights or remedies you have or
        may have against TautMore with respect thereto, and, to the extent permitted
        by applicable law, agree to indemnify and hold harmless TautMore, its
        owners, operators, affiliates, licensors, and licensees to the fullest extent
        allowed by law regarding all matters related to your use of the TautMore
        Platform.`,
    ],
  },

  {
    id: 6,
    title: '6. Content and Conduct',
    content: [
      `1. As an TautMore User, you may submit User Content on the TautMore
        Platform, including videos and User comments. You understand that
        TautMore does not guarantee any confidentiality with respect to any User
        Content you submit.`,
      `2. You shall be solely responsible for your own User Content and the
        consequences of submitting and publishing such User Content on the
        TautMore Platform. You affirm, represent, and warrant that you own or have
        the necessary licenses, rights, consents, and permissions to publish the User
        Content that you submit; and you grant limited license to TautMore to all
        patent, trademark, trade secret, copyright or other proprietary rights in and to
        such User Content for publication on the TautMore Platform pursuant to these
        Terms and Conditions for the duration the said User Content is available on
        TautMore Platform.`,
      `3. For clarity, you retain your ownership rights in your User Content. However,
        by submitting User Content to TautMore, you hereby grant TautMore a
        limited, worldwide, non-exclusive, royalty-free, sub-license-able and
        transferable license to use, reproduce, distribute, display, publish, make
        available online or electronically transmit, the User Content in connection with
        the TautMore Platform and TautMore’s (and its successors’ and affiliates’)
        business, including without limitation for promoting and redistributing part or
        all of the TautMore Platform in any media formats and through any media
        channels. You also hereby grant each User of the TautMore Platform a
        limited, non-exclusive license to access your User Content through the
        TautMore Platform. The above licenses granted by you in the User Content
        you submit to the TautMore Platform terminate within a commercially
        reasonable time after you request TautMore to remove or delete your videos
        from the TautMore Platform provided you pay the mutually agreed amount
        that TautMore incurred in providing the TautMore Platform to you. You
        understand and agree, however, that TautMore may retain, but not display,
        distribute, or perform, server copies of your videos that have been removed or
        deleted. The above licenses granted by you in User comments and
        testimonial you submit are perpetual and irrevocable.`,
      `4. You further agree that User Content you submit on the TautMore Platform will
        not contain third party copyrighted material, or material that is subject to other
        third party proprietary rights, unless you have permission from the rightful
        owner of the material or you are otherwise legally entitled to post the material
        and to grant TautMore all of the license rights granted herein.`,
      `5. Furthermore, you confirm that the User Content uploaded by you is not:
        1. False, inaccurate or misleading;
        2. Infringe any third party’s copyright, patent, trademark, trade secret or
        other proprietary rights or rights of publicity or privacy;
        3. Violate any law, statue, ordinance or regulation;
        4. defamatory, unlawfully threatening or unlawfully harassing;
        5. obscene or contain pornography.
        6. Contain any viruses, trojan horses, worms, cancelbots or other
        computer programming routines that may damage, detrimentally
        interfere with, surreptitiously intercept or expropriate any system, data
        or personal information.`,
      `6. You understand and confirm that you shall not during your use of the
        TautMore Platforms at any time post or publish any content, comments or act
        in any way which will amount to harassment of any other User, whether a
        learner or an educator. If at any given point it comes to the notice of TautMore
        that you are engaged in any kind of harassment of other Users, then in such a
        case you agree that TautMore shall have the sole right to suspend/terminate
        your account with immediate effect and without any notice of such suspension
        or termination and we also reserve the right in our sole discretion to initiate
        any legal proceedings against you in such cases.`,
      `7. TautMore at its sole discretion may process any audio or audio-visual content
        uploaded by you to the TautMore Platform to confirm if the same is in
        accordance with the TautMore’s internal quality requirements and is
        compatible with the TautMore Platform.`,
      `8. TautMore does not endorse any User Content submitted on the TautMore
        Platform by any User or other licensor, or any opinion, recommendation, or
        advice expressed therein, and TautMore expressly disclaims any and all
        liability in connection with User Content. TautMore does not permit copyright
        infringing activities and infringement of intellectual property rights on the
        TautMore Platform, and TautMore will remove all User Content if properly
        notified that such Content infringes on another’s intellectual property rights.
        TautMore reserves the right to remove User Content without prior notice if it
        has reason to believe that the User Content infringes any copyright.`,
    ],
  },

  {
    id: 7,
    title: '7. Intellectual Property Rights (IPR)',
    content: [
      `The TautMore Platform, the processes, and their selection and arrangement,
          including but not limited to all text, graphics, User interfaces, visual interfaces,
          sounds and music (if any), artwork and computer code on the TautMore Platform is
          owned and controlled by TautMore and the design, structure, selection, coordination,
          expression, look and feel and arrangement of such content mentioned hereinabove
          is protected by copyright, patent and trademark laws, and various other national and
          international IPR laws and regulations. For clarity, content in the above sentence
          shall not include User Content.`,
      `Unless otherwise indicated or anything contained to the contrary, or any
          proprietary material owned by a third-party and so expressly mentioned, TautMore
          owns all IPR to and into the trademark ________ and the ___________`,
      `The mark “ ___________” is
          the sole property of TautMore. Reproduction in whole or in part of the same is strictly
          prohibited unless used with an express written permission from TautMore.`,
    ],
  },

  {
    id: 8,
    title: '8. Refund Policy',
    content: [
      `Any refunds that are to be processed shall be processed in accordance with
          TautMore’s refund policy.`,
    ],
  },

  {
    id: 9,
    title: '9. Electronic Communication',
    content: [
      `When you visit TautMore Platform or send email to us, you are
          communicating with us electronically. By communicating with us, you consent to
          receive communication from us electronically. We will communicate with you by
          email or posting notices on TautMore Platform. You agree that all agreements,
          notices, disclosures, disclaimers, offers and other communications that are provided
          to you electronically satisfy any legal requirement that such communication should
          be in writing.`,
    ],
  },

  {
    id: 10,
    title: '10. Termination of Account',
    content: [
      `1. TautMore will terminate Users access to the TautMore Platform, if`,
      `1. the User is a repeat copyright infringer;`,
      `2. the Users breaches any terms of these Terms and Conditions.`,
      `3. Violation of any applicable laws;`,
      `4. your use of the TautMore Platform disrupts our business operations or
                affects any other party/ User; or`,
      `5. you have behaved in a way, which objectively could be regarded as
          inappropriate or unlawful or illegal or which would bring any claims
          against TautMore.`,

      `2. TautMore reserves the right to decide whether User Content violates these
          Terms and Conditions for reasons other than copyright infringement, such as,
          but not limited to, pornography, obscenity, or excessive length or any other
          parameter that TautMore deems fit from time to time. TautMore may at any
          time, without prior notice and in its sole discretion, remove such User Content
          and/or terminate a User's account for submitting such material in violation of
          these Terms and Conditions.`,

      `3. We may suspend access to the TautMore Platforms or require You to change
          Your password if we reasonably believe that the TautMore Platforms have
          been or are likely to be misused, and we will notify You accordingly. Any
          termination of Your registration and/or Account or the TautMore Platforms will
          not affect liability previously incurred by You.`,
    ],
  },

  {
    id: 11,
    title: '11. Effect of Termination',
    content: [
      `In case any of the above-mentioned cases occur, TautMore reserves the right, in its
          sole discretion, to terminate or suspend the User’s account with immediate effect.
          Upon suspension or termination of the User’s account:`,
      `1. TautMore shall at its sole discretion withhold or stop any and all payments
          that are to be made to such User and evaluate the pay-outs. Payments shall
          only be made for all legitimate work that is not subject matter of any violation
          or dispute.`,
      `2. Payments shall not be made for the work that is the subject matter of any of
          the breaches.`,
      `3. User access shall be terminated immediately, and the User shall not be able
          to access the said terminated account.`,
    ],
  },

  {
    id: 12,
    title: '12. Confidentiality',
    content: [
      `You will not without obtaining prior written consent of TautMore, disclose to
          third party any Confidential Information (as defined below) that is disclosed to you
          during the term of your use of the TautMore Platform.`,
      `For the purpose of this clause Confidential Information shall include but shall
          not be limited to employee details, User list, business model, processes, ideas,
          concepts etc. relating to TautMore or TautMore Platform which are not available in
          the public domain. You acknowledge and agree that the Confidential Information so
          provided to you shall at all the time be the property of TautMore and any breach of
          the same shall cause irreparable damage to us.`,
    ],
  },

  {
    id: 13,
    title: '13. Disclaimer',
    content: [
      `YOU AGREE THAT YOUR USE OF THE TAUTMORE PLATFORM SHALL BE AT
          YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW,
          TAUTMORE, ITS OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS
          EXCLUDE ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH
          THE TAUTMORE PLATFORM AND YOUR USE THEREOF. TO THE FULLEST
          EXTENT PERMITTED BY LAW, TAUTMORE EXCLUDES ALL WARRANTIES,
          CONDITIONS, TERMS OR REPRESENTATIONS ABOUT THE ACCURACY OR
          COMPLETENESS OF THE USER CONTENT OR THE CONTENT OF ANY SITES
          SO LINKED AND ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ANY (I)
          ERRORS, MISTAKES, OR INACCURACIES OF USER CONTENT OR ANY
          CONTENT ON TAUTMORE, (II) PERSONAL INJURY OR PROPERTY DAMAGE,
          OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND
          USE OF THE TAUTMORE PLATFORM, (III) ANY UNAUTHORIZED ACCESS TO
          OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL
          INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (IV) ANY
          INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE
          TAUTMORE PLATFORM, (IV) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE
          LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE TAUTMORE
          PLATFORM BY ANY THIRD PARTY, AND/OR (V) ANY ERRORS OR OMISSIONS
          IN ANY USER CONTENT OR ANY OTHER CONTENT OR FOR ANY LOSS OR
          DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY USER
          CONTENT OR ANY OTHER CONTENT THAT IS POSTED, EMAILED,
          TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE TAUTMORE
          PLATFORM. TAUTMORE DOES NOT WARRANT, ENDORSE, GUARANTEE, OR
          ASSUME RESPONSIBILITY FOR ANY USER CONTENT OR TAUTMORE
          PLATFORM ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE
          TAUTMORE PLATFORM OR ANY HYPERLINKED SERVICES OR FEATURED IN
          ANY BANNER OR OTHER ADVERTISING, AND TAUTMORE WILL NOT BE A
          PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY
          TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF USER
          CONTENT OR SERVICES. AS WITH THE PURCHASE OF A SUBSCRIPTION OR
          SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD
          USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE
          APPROPRIATE.`,
    ],
  },

  {
    id: 14,
    title: '14. Limitation to Liability',
    content: [
      `TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT SHALL
          TAUTMORE, ITS OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS, BE
          LIABLE TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL,
          PUNITIVE, LOSSES OR EXPENSES OR CONSEQUENTIAL DAMAGES
          WHATSOEVER RESULTING FROM ANY (I) ERRORS, MISTAKES, OR
          INACCURACIES OF USER CONTENT OR ANY OTHER CONTENT AVAILABLE
          AT TAUTMORE, (II) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY
          NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF
          OUR TAUTMORE PLATFORMS, (III) ANY UNAUTHORIZED ACCESS TO OR USE
          OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION
          AND/OR FINANCIAL INFORMATION STORED THEREIN, (IV) ANY
          INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE
          TAUTMORE PLATFORM, (IV) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE
          LIKE, WHICH MAY BE TRANSMITTED TO OR THROUGH OUR TAUTMORE
          PLATFORMS BY ANY THIRD PARTY, AND/OR (V) ANY ERRORS OR
          OMISSIONS IN ANY USER CONTENT OR ANY OTHER CONTENT OR FOR ANY
          LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF YOUR USE OF
          ANY CONTENT POSTED, EMAILED, TRANSMITTED, OR OTHERWISE MADE
          AVAILABLE VIA THE TAUTMORE PLATFORM, WHETHER BASED ON
          WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY, AND
          WHETHER OR NOT TAUTMORE IS ADVISED OF THE POSSIBILITY OF SUCH
          DAMAGES.`,
      `WE UNDERSTAND THAT, IN SOME JURISDICTIONS, WARRANTIES,
          DISCLAIMERS AND CONDITIONS MAY APPLY THAT CANNOT BE LEGALLY
          EXCLUDED, IF THAT IS TRUE IN YOUR JURISDICTION, THEN TO THE EXTENT
          PERMITTED BY LAW, TAUTMORE LIMITS ITS LIABILITY FOR ANY CLAIMS
          UNDER THOSE WARRANTIES OR CONDITIONS TO EITHER SUPPLYING YOU
          THE TAUTMORE PLATFORMS AGAIN.`,
      `YOU SPECIFICALLY ACKNOWLEDGE THAT TAUTMORE SHALL NOT BE
          LIABLE FOR USER CONTENT OR THE DEFAMATORY, OFFENSIVE, OR
          ILLEGAL CONDUCT OF ANY THIRD PARTY AND THAT THE RISK OF HARM OR
          DAMAGE FROM THE FOREGOING RESTS ENTIRELY WITH YOU.
          THE TAUTMORE PLATFORM IS CONTROLLED AND OFFERED BY
          TAUTMORE FROM ITS FACILITIES IN INDIA. TAUTMORE MAKES NO
          REPRESENTATIONS THAT THE TAUTMORE PLATFORM IS APPROPRIATE OR
          AVAILABLE FOR USE IN OTHER LOCATIONS. THOSE WHO ACCESS OR USE
          THE TAUTMORE PLATFORM FROM OTHER JURISDICTIONS DO SO AT THEIR
          OWN VOLITION AND ARE RESPONSIBLE FOR COMPLIANCE WITH LOCAL
          LAW.`,
    ],
  },

  {
    id: 15,
    title: '15. Indemnity',
    content: [
      `To the extent permitted by applicable law, you agree to defend, indemnify and
          hold harmless TautMore, its officers, directors, employees, shareholders and agents,
          from and against any and all claims, damages, obligations, losses, liabilities, costs or
          debt, and expenses (including but not limited to attorneys fees) arising from: (i) your
          use of and access to the TautMore Platform; (ii) your violation of any term of these
          Terms and Conditions; (iii) your violation of any third party right, including without
          limitation any copyright, property, or privacy right; (iv) any claim that your User
          Content caused damage to a third party; or (v) violation of any applicable laws. This
          defence and indemnification obligation will survive these Terms and Conditions and
          your use of the TautMore Platform.`,
    ],
  },

  {
    id: 16,
    title: '16. Eligibility of Age and Acceptance to Terms and Conditions',
    content: [
      `You affirm that you are either more than 18 years of age, or possess legal
          parental or guardian consent, and are fully able and competent to enter into the
          terms, conditions, obligations, affirmations, representations, and warranties set forth 
          in these Terms and Conditions, and to abide by and comply with these Terms and
          Conditions. If you are under 18 years of age, then please talk to your parents or
          guardian before using the TautMore Platform.`,
      `TautMore reserves the right to refuse access to use the TautMore Platforms
          to any Users or to suspend and/or terminate access granted to existing registered
          Users at any time without according any reasons for doing so.`,
      `We provide these Terms and Conditions with our TautMore Platform so that
          you know what terms apply to your use. You acknowledge that we have given you a
          reasonable opportunity to review these Terms and Conditions and that you have
          agreed to them. You agree and acknowledge that your use of the TautMore Platform
          is subject to the most current version of the Terms and Conditions made available on
          the TautMore Platform at the time of such use.`,
    ],
  },

  {
    id: 17,
    title: '17. Force Majeure',
    content: [
      `TautMore shall not be liable for failure to perform, or the delay in performance
          of, any of its obligations if, and to the extent that, such failure or delay is caused by
          events substantially beyond the its control, including but not limited to acts of God,
          acts of the public enemy or governmental body in its sovereign or contractual
          capacity, war, terrorism, floods, fire, strikes, epidemics, civil unrest or riots, power
          outage, and/or unusually severe weather.`,
    ],
  },

  {
    id: 18,
    title: '18. Information Technology Act',
    content: [
      `These Terms and Conditions are published in accordance with the provisions
          of Rule 3 (1) of the Information Technology (Intermediaries guidelines) Rules, 2011
          that require publishing the rules and regulations, privacy policy and terms and
          conditions for access or usage of the TautMore Platform.`,
      `You are advised not to post any information or messages that are, or that may
          be construed, as being malicious, defamatory, inappropriate, slanderous,
          pornographic or otherwise sexually oriented or that makes attacks on or the
          otherwise opines or comments on any individuals or groups of individuals,
          educational institutions or any other entities whatsoever (whether companies, firms,
          or any other institutions). You also agree not to post any information to which you do
          not have copyrights or other appropriate permissions to post in public forum. Your
          failure to comply with these terms may result in removal of your postings without
          prior notice to User. The IP address of all posts is recorded to aid in enforcing these
          conditions.`,
    ],
  },

  {
    id: 19,
    title: '19. Other Laws, Rules and Regulations',
    content: [
      `Certain laws require to maintain data with respect to the TautMore Platform
          and other personal information in a prescribed format and TautMore will use all the
          information to the extent required in compliance with the applicable laws and as may
          be directed or amended from time to time.`,
    ],
  },

  {
    id: 20,
    title: '20. Governing Law and Jurisdiction',
    content: [
      `The Terms and Conditions are governed by and constructed in accordance
          with the laws of India, without reference to conflict of laws principles and you
          irrevocably and unconditionally submit to the exclusive jurisdiction of the courts
          located in Vijayawada, Andhra Pradesh, India.`,
    ],
  },

  {
    id: 21,
    title: '21. General',
    content: [
      `If any part of these Terms and Conditions is unlawful, void or unenforceable,
          that part of will be deemed severable and will not affect the validity and enforceability
          of any remaining provisions. Any notice required to be given in connection with the
          TautMore Platform shall be in writing and sent to the registered office of TautMore.
          We do not guarantee continuous, uninterrupted or secure access to the TautMore
          Platform, and operation of the TautMore Platform may be interfered by numerous
          factors outside our control. Headings are for reference purpose only and in no way
          define, limit, construe or describe the scope or extent of such section. Our failure to
          act with respect to any breach by you or others does not waive our right to act with
          respect to subsequent or similar breaches.`,
    ],
  },

  {
    id: 22,
    title: '22. Refund Policy',
    content: [
      `Please read the subscription terms and conditions carefully before subscribing
          to any of the subscription plans, as once you have subscribed you cannot change,
          cancel your subscription plan. Once you subscribe and make the required payment,
          it shall be final and there cannot be any changes or modifications to the same and
          neither will there be any refund.`,
    ],
  },

  // {
  //   id: 23,
  //   title: '23. Consumer Grievances',
  //   content: [
  //     `Nodal Officer Details –`,
  //     `Name:`,
  //     `Contact:`,

  //     `Grievance Officer Details – `,
  //     `Name:`,
  //     `Contact:`,

  //     `(NOTE: Client to mention the relevant details here)`,
  //   ],
  // },
];

export const accordionpolicy = [
  {
    id: 1,
    title: '1. What personal information do we process?',
    content: [
      `When you visit, use, or navigate our Services, we may process personal information depending on how you interact with TautMore Learning Private Limited and the Services, the choices you make, and the products and features you use. 
      `,
    ],
  },

  {
    id: 2,
    title: '2. Do we process any sensitive personal information?',
    content: [
      `We may process sensitive personal information when necessary with your consent or as otherwise permitted by applicable law.`,
    ],
  },

  {
    id: 3,
    title: '3. Do we receive any information from third parties?',
    content: [`We do not receive any information from third parties.`],
  },

  {
    id: 4,
    title: '4. How do we process your information?',
    content: [
      `We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so.`,
    ],
  },

  {
    id: 5,
    title:
      '5. In what situations and with which parties do we share personal information?',
    content: [
      `We may share information in specific situations and with specific third parties. Contacting us at admin@tautmore.com.
      `,
    ],
  },

  {
    id: 6,
    title: '6. How do we keep your information safe?',
    content: [
      `We have organizational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information.`,
    ],
  },

  {
    id: 7,
    title: '7. What are your rights?',
    content: [
      `Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information.
      `,
    ],
  },

  {
    id: 8,
    title: '8. How do you exercise your rights?',
    content: [
      `The easiest way to exercise your rights is by contacting us at support@tautmore.com. We will consider and act upon any request in accordance with applicable data protection laws.`,
    ],
  },
];
