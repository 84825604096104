import React from 'react';
import BigCalendar from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { array, func } from 'prop-types';

const CalendarMonth = ({ events, views, setDate, setChangeView }) => {
  const onNavigate = (e) => {
    setDate(e);
    setChangeView(true);
  };

  return (
    <BigCalendar
      events={events}
      views={views}
      step={60}
      showMultiDayTimes
      defaultDate={new Date()}
      onNavigate={(e) => onNavigate(e)}
    />
  );
};

CalendarMonth.propTypes = {
  events: array.isRequired,
  views: array.isRequired,
  setDate: func.isRequired,
  setChangeView: func.isRequired,
};

export default CalendarMonth;
