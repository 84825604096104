import Dashboardicon from '../../../../assets/images/dashboardicon.png';
import MyClassIcon from '../../../../assets/icons/newLeftDashboardIcons/online-education.svg';
import CalenderIcon from '../../../../assets/icons/newLeftDashboardIcons/Calender.svg';
import MyStudentsIcon from '../../../../assets/icons/newLeftDashboardIcons/My-students.svg';
import MySubjectIcon from '../../../../assets/icons/newLeftDashboardIcons/subject.svg';
import WhiteBoardIcon from '../../../../assets/icons/newLeftDashboardIcons/whiteboard.svg';
import CoCurricularIcon from '../../../../assets/icons/newLeftDashboardIcons/Co-curricular.svg';

import newTeacherClassIcon from '../../../../assets/icons/teacherIcon/classes.svg';
import newTeacherCalenderIcon from '../../../../assets/icons/teacherIcon/calender.svg';
import newTeacherStudentsIcon from '../../../../assets/icons/teacherIcon/students.svg';
import newTeacherSubjectsIcon from '../../../../assets/icons/teacherIcon/subjects.svg';
import newTeacherCoCurIcon from '../../../../assets/icons/teacherIcon/co-curriculars.svg';
import newTeacherMyProfileIcon from '../../../../assets/icons/teacherIcon/my-profile.svg';
import newTeacherMyWhiteBoardIcon from '../../../../assets/icons/teacherIcon/whiteboard.svg';
import newTeacherRevenue from '../../../../assets/icons/teacherIcon/revenue.svg';
import newTeacherLogoutIcon from '../../../../assets/icons/teacherIcon/logout.svg';

import { viewTypeData } from './DashboardViewData';

export const sidebardata = {
  title: 'OVERVIEW',
  data: [
    {
      title: 'Dashboard',
      image: Dashboardicon,
      to: viewTypeData?.DASHBOARD,
    },
    {
      title: 'My classes',
      image: newTeacherClassIcon,
      to: viewTypeData?.CLASSES,
    },
    {
      title: 'My calendar',
      image: newTeacherCalenderIcon,
      to: viewTypeData?.CALENDAR,
    },
    {
      title: 'My students',
      image: newTeacherStudentsIcon,
      to: viewTypeData?.STUDENTS,
    },
    {
      title: 'My subjects',
      image: newTeacherSubjectsIcon,
      to: viewTypeData?.SUBJECTS,
    },
    {
      title: 'My payments',
      image: Dashboardicon,
      to: viewTypeData?.PAYMENTS,
    },
    // {
    //   title: 'White Board',
    //   image: newTeacherMyWhiteBoardIcon,
    //   to: viewTypeData?.WHITEBOARD,
    // },
    {
      title: 'Co-curricular',
      image: newTeacherCoCurIcon,
      to: viewTypeData?.COCURRICULAR,
    },
  ],
};
export const accountData = {
  title: 'Account',
  data: [],
};

export const teachersidebardata = {
  title: 'OVERVIEW',
  data: [
    {
      title: 'My Classes',
      image: newTeacherClassIcon,
      to: viewTypeData?.CLASSES,
    },
    {
      title: 'My Calendar',
      image: newTeacherCalenderIcon,
      to: viewTypeData?.CALENDAR,
    },
    {
      title: 'My Students',
      image: newTeacherStudentsIcon,
      to: viewTypeData?.STUDENTS,
    },
    {
      title: 'My Subjects',
      image: newTeacherSubjectsIcon,
      to: viewTypeData?.SUBJECTS,
    },

    {
      title: 'My Revenue',
      image: newTeacherRevenue,
      to: viewTypeData?.REVENUE,
    },
    {
      title: 'Co-curricular',
      image: newTeacherCoCurIcon,
      to: viewTypeData?.COCURRICULAR,
    },
  ],
};
export const teachersidebardataWithoutCoCuri = {
  title: 'OVERVIEW',
  data: [
    {
      title: 'My classes',
      image: newTeacherClassIcon,
      to: viewTypeData?.CLASSES,
    },
    {
      title: 'My calendar',
      image: newTeacherCalenderIcon,
      to: viewTypeData?.CALENDAR,
    },
    {
      title: 'My students',
      image: newTeacherStudentsIcon,
      to: viewTypeData?.STUDENTS,
    },
    {
      title: 'My subjects',
      image: newTeacherSubjectsIcon,
      to: viewTypeData?.SUBJECTS,
    },
    {
      title: 'My Revenue',
      image: newTeacherRevenue,
      to: viewTypeData?.REVENUE,
    },

    // {
    //   title: 'White Board',
    //   image: newTeacherMyWhiteBoardIcon,
    //   to: viewTypeData?.WHITEBOARD,
    // },
  ],
};

export const teachersidebardataWitCoCuri = {
  title: 'OVERVIEW',
  data: [
    {
      title: 'Co-curricular',
      image: newTeacherCoCurIcon,
      to: viewTypeData?.COCURRICULAR,
    },
    {
      title: 'My calendar',
      image: newTeacherCalenderIcon,
      to: viewTypeData?.CALENDAR,
    },

    {
      title: 'My Revenue',
      image: newTeacherRevenue,
      to: viewTypeData?.REVENUE,
    },

    // {
    //   title: 'White Board',
    //   image: newTeacherMyWhiteBoardIcon,
    //   to: viewTypeData?.WHITEBOARD,
    // },
  ],
};
