import React, { useEffect, useState } from 'react';
import { object } from 'prop-types';
import Header from '../views/components/header/Header';
import Footer from '../views/components/footer/Footer';
import StickyButton from './sticky_button';
import BookDemoModal from './book-a-demo';
import { useHistory } from 'react-router';

// const useScroll = () => {
//   const elRef = useRef(null);
//   const executeScroll = () => elRef.current.scrollIntoView();

//   return [executeScroll, elRef];
// };

const Layout = ({ children }) => {
  const [bookDemo, setBookDemo] = useState(false);
  const history = useHistory();

  // const screenSize
  // const [executeScroll, elRef] = useScroll();
  // useEffect(executeScroll, []);
  return (
    <>
      {/* <div ref={elRef}> */}
      <Header />
      {/* </div> */}
      <div className=' custom-container m-auto'>{children}</div>

      <StickyButton
        content='BOOK A DEMO'
        // onButtonPress={() => {
        //   scrollTo('braingym');
        // }}
        onButtonPress={
          () => {
            history.push('/book-demo');
          }
          // setBookDemo(true)
        }
        // onButtonPress={()=>{history.push('/login')}}
      />
      {bookDemo ? (
        <BookDemoModal modal={bookDemo} setModal={setBookDemo} />
      ) : (
        ''
      )}
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: object.isRequired,
};
export default Layout;
