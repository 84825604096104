import { func, string } from 'prop-types';
import React from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'react-bootstrap';
import { useHistory } from 'react-router';

const Popup = ({ modal, setModal, title, stepdata }) => {
  const history = useHistory();
  const setstepdata = () => {
    // setStep(stepdata);
    history.push('/payment');
  };
  const noclick = () => {
    setModal(false);
  };

  return (
    <div className='popup'>
      <Modal
        isOpen
        show={modal}
        toggle={() => setModal(!modal)}
        className='popup'
      >
        {/* <ModalHeader toggle={toggle}>Modal title</ModalHeader> */}
        <ModalBody>{title}</ModalBody>
        <ModalFooter>
          <Button color='primary' onClick={() => setstepdata()}>
            Procced to Pay
          </Button>
          <Button color='secondary' onClick={() => noclick()}>
            {/* <Button color="secondary"> */}
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
Popup.propTypes = {
  // setStep: func.isRequired,
  setModal: func.isRequired,
  modal: string.isRequired,
  title: string.isRequired,
  stepdata: string.isRequired,
};

export default Popup;
