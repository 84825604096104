import { func, string } from 'prop-types';
import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalTitle,
} from 'react-bootstrap';
import { useHistory } from 'react-router';
import BookADemoWrapper from './book-a-demo-wrapper';
import swal from 'sweetalert';
import Success from '../../views/components/newRegistration/Success';
import appStore from '../../assets/images/appstore.png';
import googlePlay from '../../assets/images/googplePlay.png';
import {
  clearOtpBookDemo,
  resetBookDemoAction,
} from '../../stores/Auth/AuthAction';
import { connect } from 'react-redux';

const BookDemoModal = ({
  modal,
  setModal,
  clearOtpBookDemo,
  resetBookDemo,
}) => {
  const [isSuccess, setSuccess] = useState(false);
  return (
    <div className='popup'>
      <Modal
        isOpen
        show={modal}
        toggle={() => setModal(false)}
        className={
          isSuccess
            ? 'popup book-a-demo-popup book-demo-success'
            : 'popup book-a-demo-popup'
        }
      >
        {isSuccess ? (
          <Success
            demo={true}
            setModal={() => {
              setSuccess(false);
              setModal(false);
            }}
          />
        ) : (
          <>
            <ModalTitle className='title d-flex justify-content-between align-items-center'>
              <span className='flex-grow-1'>Add your details</span>
              <button
                className='btn-close'
                onClick={() => {
                  clearOtpBookDemo();
                  setModal(false);
                }}
              ></button>
            </ModalTitle>
            <ModalBody>
              <BookADemoWrapper
                modal={modal}
                setModal={() => setModal(false)}
                setSuccess={() => setSuccess(true)}
              />
            </ModalBody>
            <div className='stickyFooterMobile book-demo-stickyFooterMobile'>
              <div className='stickyFooterInside'>
                <a href='https://apps.apple.com/us/app/tautmore/id1612243202'>
                  <img src={appStore} alt='app store' />
                </a>

                <a href='https://play.google.com/store/apps/details?id=com.tautmore'>
                  <img src={googlePlay} alt='google play' />
                </a>
              </div>
            </div>
          </>
        )}
      </Modal>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  clearOtpBookDemo: () => dispatch(clearOtpBookDemo()),
  resetBookDemo: () => dispatch(resetBookDemoAction()),
});
export default connect(null, mapDispatchToProps)(BookDemoModal);
